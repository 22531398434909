import React, { useState } from "react";
import card4 from "../../../Media/4.png";
import card1 from "../../../Media/1.png";
import card2 from "../../../Media/2.png";
import card3 from "../../../Media/3.png";
import card6 from "../../../Media/6.png";
import card5 from "../../../Media/5.png";
import { useTranslation } from "react-i18next";
import { AboutCard } from "./AboutCard";

export default function About() {
  const { t, i18n } = useTranslation();


  return (
    <>
      <section
        id="about"
        className="px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 py-10 3xl:py-16 h-auto  bg-main-gray z-40 relative text-white"
      >
        <h3 className="text-3xl 2xl:text-4xl 3xl:text-4xl font-bold mb-10 3xl:mb-16">
          {t("whatWeDo")}
        </h3>

        <p className="pb-16 2xl:text-lg 3xl:text-xl">
          {t("aboutDesc")}
          <br />
          {t("aboutDesc2")}
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-16 gap-x-6">

          <AboutCard
            img={card1}
            name={t("scenario")}
            desc={t("scenarioDesc")}
          />
          <AboutCard
            img={card2}
            name={t("animatic")}
            desc={t("animaticDesc")}
          />
          <AboutCard
            img={card3}
            name={t("visual")}
            desc={t("visualDesc")}
          />
          <AboutCard
            img={card6}
            name={t("rigging")}
            desc={t("riggingDesc")}
          />
          <AboutCard
            img={card5}
            name={t("animation")}
            desc={t("animationDesc")}
          />
          <AboutCard
            img={card4}
            name={t("edit")}
            desc={t("editDesc")}
          />

        </div>
      </section>
    </>
  );
}
