import React, { useEffect, useState } from "react";
import { Project } from "./Components/Project";
import { Header } from "../Header";
import Footer2 from "../Footer2";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useTranslation } from "react-i18next";
import mainlogo_ru from "../../Media/logo_artwall_ru.png";
import mainlogo_en from "../../Media/logo_artwall_en.png";

export default function Projects() {
  const { t, i18n, ready } = useTranslation();

  const [details, setDetails] = useState(false);
  const [showreel, setShowreel] = React.useState<any>();

  const getData = async () => {
    const valRef = collection(db, "projects");
    const dataDB = await getDocs(valRef);
    const allData = dataDB.docs.map((val) => ({ id: val.id, ...val.data() }));

    setShowreel(allData);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Header />
      <section
        id="projects"
        className="px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 pb-10 pt-[108px] 3xl:pb-16 3xl:pt-[132px] border-gray-light bg-main-gray z-40 relative text-white"
      >
        <div>
          <h3 className="pb-10 3xl:pb-10 text-3xl 2xl:text-4xl 3xl:text-5xl font-bold">
            {t("mainProjects")}
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 xl:!grid-rows-2 xl:overflow-hidden 3xl:grid-cols-4 gap-5">
             {showreel !== undefined &&
              showreel !== null &&
              showreel.map((value: any) => {
                return (
                  <>
                    {i18n.language == "ru" ? (
                      <>
                        <Project
                          name={value.name}
                          video={value.url}
                          link={value.youtube}
                          typeOf={value.desc}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <Project
                          name={value.nameEN}
                          video={value.url}
                          link={value.youtube}
                          typeOf={value.descEN}
                        />
                      </>
                    )}
                  </>
                );
              })}
          </div>

        </div>
      </section>
      <Footer2 />
    </>
  );
}
