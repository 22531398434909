import type { FieldValues } from "react-hook-form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { db, storage } from "../firebase";
import {
  Firestore,
  limit,
  limitToLast,
  orderBy,
  query,
  serverTimestamp,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import {
  ref,
  uploadBytes,
  listAll,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import { NavControl } from "./NavControl";
import { useAuth } from "../contexts/AuthContext2";

export default function Requests() {
  const [url, setUrl] = React.useState<any>();
  const [data, setData] = React.useState<any>();
  const [data2, setData2] = React.useState<any>();
  const [deleted, isDeleted] = useState(false);
  const [deleted2, isDeleted2] = useState(false);

  const [toggle, setToggle] = useState(false);
  const { user } = useAuth();

  const getData2 = async () => {
    const q1 = query(collection(db, "clients"), orderBy("date", "desc"));
    const dataDB = await getDocs(q1);
    const allData = dataDB.docs.map((val) => ({ id: val.id, ...val.data() }));

    setData(allData);
  };

  const deleteItem2 = async (id: any, name: any) => {
    const docRef = doc(db, "clients", id);
    try {
      await deleteDoc(docRef);
      isDeleted(true);
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };
  const getData = async () => {
    const q1 = query(collection(db, "job"), orderBy("date", "desc"));
    const dataDB = await getDocs(q1);
    const allData = dataDB.docs.map((val) => ({ id: val.id, ...val.data() }));
    setData2(allData);
  };

  const deleteItem = async (id: any, name: any) => {
    const docRef = doc(db, "job", id);
    try {
      await deleteDoc(docRef);
      isDeleted2(true);
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getData();
    getData2();
  }, []);
  const [hintLink, isHintLink] = useState(false);

  const toggleHintLink = () => {
    isHintLink(!hintLink);
  };

  return (
    <>
      {user && (
        <>
          <NavControl />
          <section className="pb-10 pt-[108px] 3xl:pb-16 3xl:pt-[132px] border-lime-lighter bg-main-gray z-40 relative text-white">
            <h3 className="px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 text-3xl 2xl:text-4xl 3xl:text-5xl font-bold  pb-10 3xl:pb-16">
              ВАКАНСИИ
            </h3>
            {deleted2 && (
              <>
                <span className="px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 pb-2 font-bold text-lime">
                  УДАЛЕНО
                </span>
              </>
            )}
            <div className=" px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-white uppercase bg-lime-lighter/10">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Вакансия
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Имя
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Телефон
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Email
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Дата создания
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Ссылка на соцсети
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Портфолио
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Действие
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data2 !== undefined &&
                    data2 !== null &&
                    data2.map((value: any) => {
                      return (
                        <tr className="odd:bg-white odd:dark:bg-transparent even:bg-lime-lighter/10 even:dark:bg-lime-lighter/10 border-b dark:border-gray-700">
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-wrap dark:text-white"
                          >
                            {value.vacancy}
                          </th>
                          <td className="px-6 py-4">
                            {value.firstName} {value.lastName}
                          </td>
                          <td className="px-6 py-4">{value.phone}</td>
                          <td className="px-6 py-4">{value.email}</td>
                          <td className="px-6 py-4">
                            {value.date.toDate().toDateString()}{" "}
                            {value.date.toDate().toLocaleTimeString("ru-RU")}
                          </td>
                          <td className="px-6 py-4 relative">
                          <button
                      onClick={toggleHintLink}
                      type="button"
                      className="relative flex self-center h-[25px] w-24 items-center rounded-lg border-2 border-lime !text-lime text-sm 2xl:text-lg font-semibold justify-center overflow-hidden bg-transparent hover:!text-main-gray shadow-2xl transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:bg-lime before:duration-500 before:ease-out hover:shadow-lime-lighter hover:before:h-56 hover:before:w-56"
                      >
                              <a
                                className="relative z-10 py-2"
                              >
                                Просмотр
                              </a>
                    </button>
                    {hintLink && (
                      <>
                        <div className="mt-[5px] absolute z-50 w-auto inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm  dark:bg-gray-700">
                          <div>
                          {value.media}
                          <a className="" href={value.media}><i className="fa-solid fa-link text-lime"></i></a>
                          <button onClick={() =>  navigator.clipboard.writeText(`${value.media}`)}><i className="pl-5 fa-solid fa-copy text-lime"></i></button>
                          </div>
                          <div className="tooltip-arrow"></div>
                        </div>
                      </>
                    )}
                          </td>
                          <td className="px-6 py-4">
                            {" "}
                            <button
                              className="relative flex self-center h-[25px] w-24 items-center rounded-lg border-2 border-lime !text-lime text-sm 2xl:text-lg font-semibold justify-center overflow-hidden bg-transparent hover:!text-main-gray shadow-2xl transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:bg-lime before:duration-500 before:ease-out hover:shadow-lime-lighter hover:before:h-56 hover:before:w-56"
                            >
                              <a
                                className="relative z-10 py-2"
                                href={value.portfolio}
                                target="_blank"
                              >
                                Просмотр
                              </a>
                            </button>
                          </td>

                          <td className="px-6 py-4">
                            <button
                              onClick={(e: any) =>
                                deleteItem(value.id, value.name)
                              }
                              className="relative flex self-center h-[25px] w-24 items-center rounded-lg border-2 border-lime !text-lime text-sm 2xl:text-lg font-semibold justify-center overflow-hidden bg-transparent hover:!text-main-gray shadow-2xl transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:bg-lime before:duration-500 before:ease-out hover:shadow-lime-lighter hover:before:h-56 hover:before:w-56"
                            >
                              <span className="relative z-10 py-2">
                                Удалить
                              </span>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </section>
          <section className="bg-main-gray  !z-40 relative text-white border-b border-lime-lighter py-10 3xl:py-16">
            <h3 className="px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 text-3xl 2xl:text-4xl 3xl:text-5xl font-bold  pb-10 3xl:pb-16">
              ЗАЯВКИ С САЙТА
            </h3>
            {deleted && (
              <>
                <span className="px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 pb-2 font-bold text-lime">
                  УДАЛЕНО
                </span>
              </>
            )}
            <div className=" px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-white uppercase bg-lime-lighter/10">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Имя
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Телефон
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Email
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Сообщение
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Дата создания
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Действие
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data !== undefined &&
                    data !== null &&
                    data.map((value: any) => {
                      return (
                        <tr className="odd:bg-white odd:dark:bg-transparent even:bg-lime-lighter/10 even:dark:bg-lime-lighter/10 border-b dark:border-gray-700">
                          <td className="px-6 py-4">{value.name}</td>
                          <td className="px-6 py-4">{value.phone}</td>
                          <td className="px-6 py-4">{value.email}</td>
                          <td className="px-6 py-4">{value.message}</td>
                          <td className="px-6 py-4">
                            {value.date.toDate().toDateString()}{" "}
                            {value.date.toDate().toLocaleTimeString("ru-RU")}
                          </td>
                          <td className="px-6 py-4">
                            <button
                              onClick={(e: any) =>
                                deleteItem2(value.id, value.name)
                              }
                              className="relative flex self-center h-[25px] w-24 items-center rounded-lg border-2 border-lime !text-lime text-sm 2xl:text-lg font-semibold justify-center overflow-hidden bg-transparent hover:!text-main-gray shadow-2xl transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:bg-lime before:duration-500 before:ease-out hover:shadow-lime-lighter hover:before:h-56 hover:before:w-56"
                            >
                              <span className="relative z-10 py-2">
                                Удалить
                              </span>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </section>
        </>
      )}
    </>
  );
}
