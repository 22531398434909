import type { FieldValues } from "react-hook-form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { db, storage } from "../firebase";
import {
  Firestore,
  limit,
  limitToLast,
  orderBy,
  query,
  serverTimestamp,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import {
  ref,
  uploadBytes,
  listAll,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import { NavControl } from "./NavControl";
import { useAuth } from "../contexts/AuthContext2";

const schema = z.object({
  file: z
    .any(),
  link: z
    .string()
    .nonempty("Поле не может быть пустым")
    .includes("/embed/", { message: "Не похоже на ссылку" }),
});

type TSchema = z.infer<typeof schema>;

export default function ShowreelControl() {
  const [url, setUrl] = React.useState<any>();
  const [data, setData] = React.useState<any>();
  const [deleted, isDeleted] = useState(false);

  const [showreel, setShowreel] = React.useState<any>();

  const [link, setLink] = React.useState<any>();
  const [name, setName] = React.useState<any>();
  const [downloadName, setDownloadName] = React.useState<any>();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    getValues,
  } = useForm<TSchema>({
    resolver: zodResolver(schema),
  });
  const handleUpload = (e: any) => {
    setDownloadName(e.target.files[0].name);
    setLoading(true);
    const fileRef = ref(storage, `showreel/${uuidv4()}`);
    uploadBytes(fileRef, e.target.files[0]).then((data) => {
      setName(data.metadata.name);
      getDownloadURL(data.ref).then((val) => {
        setUrl(val);
        setLoading(false);
      });
    });
  };
  const onSubmit = async (data: FieldValues) => {
    const valRef = collection(db, "showreel");
    try {
      await addDoc(valRef, {
        date: serverTimestamp(),
        url: url,
        name: name,
        ybLink: getValues("link"),
        downloadName: downloadName,
      });
    } catch (e) {
      console.log(e);
    }
    setAlert(true);
    reset();
    window.location.reload();
  };
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };
  const [hintLink, isHintLink] = useState(false);
  const [hintVideo, isHintVideo] = useState(false);

  const toggleHintLink = () => {
    isHintLink(!hintLink);
  };
  const toggleHintVideo = () => {
    isHintVideo(!hintVideo);
  };
  const getData = async () => {
    const valRef = collection(db, "showreel");
    const q1 = query(collection(db, "showreel"), orderBy("date", "desc"));
    const dataDB = await getDocs(q1);
    const allData = dataDB.docs.map((val) => ({ id: val.id, ...val.data() }));

    setShowreel(allData);
    const q = query(
      collection(db, "showreel"),
      orderBy("date"),
      limitToLast(1)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setData(doc.data());
    });
  };

  const deleteItem = async (id: any, name: any) => {
    const docRef = doc(db, "showreel", id);
    try {
      await deleteDoc(docRef);
      isDeleted(true);
      window.location.reload();
    } catch (e) {
      console.log(e);
    }

    const desertRef = ref(storage, `showreel/${name}`);

    deleteObject(desertRef)
      .then(() => {
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getData();
  }, []);
  const [mouseOver, isMouseOver] = useState(false);

  const handleOnMouseOver = (e: React.MouseEvent<HTMLElement>) => {
    isMouseOver(true);
  };
  const handleOnMouseOut = (e: React.MouseEvent<HTMLElement>) =>
    isMouseOver(false);

  const linkClassName = errors.link ? "border-error" : "border-lime-lighter";
  const linkClasses = [
    "w-full  bg-main-gray border-b-2  px-3 py-2 outline-none focus:bg-main-gray transition duration-100 focus:border-lime",
    linkClassName,
  ];
  const fileClassName = errors.file ? "border-error" : "border-lime-lighter";
  const fileClasses = [
    "w-full  bg-main-gray border-b-2  px-3 py-2 outline-none focus:bg-main-gray transition duration-100 focus:border-lime",
    fileClassName,
  ];
  const { user } = useAuth();

  return (
    <>
      {user && (
        <>
          <NavControl />
          <section
            id="form"
            className="pb-10 pt-[108px] 3xl:pb-16 3xl:pt-[132px] border-gray-light bg-main-gray z-40 relative text-white"
          >
            <h3 className="px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 text-3xl 2xl:text-4xl 3xl:text-5xl font-bold  pb-10 3xl:pb-16">
              ПОМЕНЯТЬ SHOWREEL
            </h3>

            <div className="px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 flex flex-col justify-between gap-16">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="2xl:text-lg 3xl:text-xl max-w-screen-sm gap-5"
              >
                {alert && <span>Загружено</span>}
                <div className="mb-3">
                  <div className="relative">
                    <input
                      className={fileClasses.join(" ")}
                      id="file_input"
                      type="file"
                      {...register("file")}
                      onChange={(e) => handleUpload(e)}
                    ></input>
                    <button
                      onClick={toggleHintVideo}
                      type="button"
                      className="absolute right-0 w-8 h-8 rounded-full border border-2 border-lime focus:outline-none font-medium text-sm text-center"
                    >
                      <i className="text-lime fa-solid fa-question"></i>
                    </button>
                    {hintVideo && (
                      <>
                        <div className="ml-[8px] lg:absolute z-50 lg:w-[200px] mt-[5px] lg:mt-0 inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm  dark:bg-gray-700">
                        Формат mp4, желательно не более 5МБ, 2-3 сцены
                          <div className="tooltip-arrow"></div>
                        </div>
                      </>
                    )}
                  </div>

                  {loading && (
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  {errors.file && (
                    <p className="text-error pt-1">{`${errors.file.message}`}</p>
                  )}
                </div>

                <div className="mb-3">
                  <div className="relative">
                    <input
                      {...register("link")}
                      placeholder="Ссылка на youtube*"
                      name="link"
                      className={linkClasses.join(" ")}
                    />
                    <button
                      onClick={toggleHintLink}
                      type="button"
                      className="absolute right-0 w-8 h-8 rounded-full border border-2 border-lime focus:outline-none font-medium text-sm text-center"
                    >
                      <i className="text-lime fa-solid fa-question"></i>
                    </button>
                    {hintLink && (
                      <>
                        <div className="ml-[8px] lg:absolute z-50 lg:w-[200px] mt-[5px] lg:mt-0 inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm  dark:bg-gray-700">
                        Зайти на видео в youtube → нажать 'Поделиться' →
                          нажать 'Встроить' → Скопировать ссылку после
                          src='(скопировать без кавычек)'
                          <div className="tooltip-arrow"></div>
                        </div>
                      </>
                    )}
                  </div>

                  {errors.link && (
                    <p className="text-error pt-1">{`${errors.link.message}`}</p>
                  )}
                </div>

                <div className="flex items-center mt-5 justify-between sm:col-span-2">
                  {loading ? (
                    <button
                      type="submit"
                      className="relative flex self-center cursor-not-allowed opacity-50  h-[50px] w-40 items-center rounded-lg border-2 border-lime !text-lime text-sm 2xl:text-lg 3xl:text-xl font-semibold justify-center overflow-hidden bg-transparent hover:!text-main-gray shadow-2xl transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:bg-lime before:duration-500 before:ease-out hover:shadow-lime-lighter hover:before:h-56 hover:before:w-56"
                    >
                      <span className="relative z-10">Загрузить</span>
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="relative flex self-center  h-[50px] w-40 items-center rounded-lg border-2 border-lime !text-lime text-sm 2xl:text-lg 3xl:text-xl font-semibold justify-center overflow-hidden bg-transparent hover:!text-main-gray shadow-2xl transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:bg-lime before:duration-500 before:ease-out hover:shadow-lime-lighter hover:before:h-56 hover:before:w-56"
                    >
                      <span className="relative z-10">Загрузить</span>
                    </button>
                  )}
                </div>
              </form>
            </div>
          </section>

          {data !== undefined && data !== null && (
            <section
              id="showreel"
              className="pt-10 3xl:pt-16 2xl:h-screen bg-main-gray z-40 relative text-white" //     ? //   modal // {
              //     : "pt-10 3xl:pt-16 xl:h-screen bg-main-gray z-30 relative text-white"
              // }
            >
              <h3 className="px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 text-3xl 2xl:text-4xl 3xl:text-5xl font-bold  py-10 3xl:py-16">
                ПРЕДСТАВЛЕНИЕ
              </h3>
              <p className="px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 2xl:text-lg 3xl:text-xl">
                {/* {t("srDesc")} */}
              </p>
              <p className="px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 mb-10 2xl:text-lg 3xl:text-xl">
                {/* {t("srDesc2")} */}
              </p>
              <div
                className="group card relative overflow-hidden h-5/6 text-center z-40"
                onMouseOver={handleOnMouseOver}
                onMouseOut={handleOnMouseOut}
              >
                {mouseOver ? (
                  <button
                    className=" absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-lime  text-7xl sm:text-8xl z-40"
                    onClick={toggleModal}
                  >
                    <i className="animate-fade-up animate-duration-[1500ms] animate-ease-in-out animate-normal animate-fill-forwards fa-solid fa-play z-20"></i>{" "}
                  </button>
                ) : (
                  <>
                    <div className="absolute inset-0 h-full bg-black/25 "></div>
                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                      <h1 className="animate-fade-up animate-duration-[1500ms] animate-ease-in-out animate-normal animate-fill-forwards text-3xl lg:text-5xl 3xl:text-6xl">
                        SHOWREEL
                      </h1>
                    </div>
                  </>
                )}

                <video
                  src={data.url}
                  loop
                  className="w-auto lg:w-full h-auto z-40 transition duration-200 group-hover:scale-110"
                  muted
                  playsInline
                  autoPlay
                ></video>
              </div>
              {modal && (
                <div className="overflow-hidden overscroll-auto modal overlay  fixed h-screen w-screen inset-0 !z-40">
                  <div className="overlay absolute h-screen w-screen  top-0 right-0 bottom-0 ">
                    <div className="w-full h-full z-40 flex bg-gray-dark/50 justify-center items-center backdrop-blur-md"></div>
                    <div
                      onClick={toggleModal}
                      className="animate-fade-up animate-once animate-duration-1000 absolute top-20 right-5 sm:right-10 md:right-14 xl:right-24 3xl:top-32 !z-40"
                    >
                      <i className="fa-solid fa-xmark text-5xl 3xl:text-6xl text-lime"></i>
                    </div>
                    <div className="overflow-hidden z-40 modal-content absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ">
                      <iframe
                        className=" !w-[320px] !h-[200px] sm:!h-[270px] sm:!w-[490px] md:!h-[350px] md:!w-[600px] lg:!h-[350px] lg:!w-[640px] xl:!h-[390px] xl:!w-[750px] 2xl:!h-[500px] 2xl:!w-[1024px] auto rounded-lg"
                        src={data.ybLink}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              )}
            </section>
          )}

          <section className="bg-main-gray  !z-40 relative text-white border-b border-lime-lighter py-10 3xl:py-16">
            <h3 className="px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 text-3xl 2xl:text-4xl 3xl:text-5xl font-bold  pb-10 3xl:pb-16">
              ИСТОРИЯ
            </h3>
            {deleted && (
              <>
                <span className="px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 pb-2 font-bold text-lime">
                  УДАЛЕНО
                </span>
              </>
            )}
            <div className=" px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-white uppercase bg-lime-lighter/10">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Название видео
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Ссылка на видео
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Ссылка на youtube
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Дата создания
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Действие
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {showreel !== undefined &&
                    showreel !== null &&
                    showreel.map((value: any) => {
                      return (
                        <tr className="odd:bg-white odd:dark:bg-transparent even:bg-lime-lighter/10 even:dark:bg-lime-lighter/10 border-b dark:border-gray-700">
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {value.downloadName}
                          </th>
                          <td className="px-6 py-4">
                            <button
                              className="relative flex self-center h-[25px] w-24 items-center rounded-lg border-2 border-lime !text-lime text-sm 2xl:text-lg font-semibold justify-center overflow-hidden bg-transparent hover:!text-main-gray shadow-2xl transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:bg-lime before:duration-500 before:ease-out hover:shadow-lime-lighter hover:before:h-56 hover:before:w-56"
                            >
                              <a
                                className="relative z-10 py-2"
                                href={value.url}
                                target="_blank"
                              >
                                Просмотр
                              </a>
                            </button>
                          </td>
                          <td className="px-6 py-4">
                            <button
                              className="relative flex self-center h-[25px] w-24 items-center rounded-lg border-2 border-lime !text-lime text-sm 2xl:text-lg font-semibold justify-center overflow-hidden bg-transparent hover:!text-main-gray shadow-2xl transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:bg-lime before:duration-500 before:ease-out hover:shadow-lime-lighter hover:before:h-56 hover:before:w-56"
                            >
                              <a
                                className="relative z-10 py-2"
                                href={value.ybLink}
                                target="_blank"
                              >
                                Просмотр
                              </a>
                            </button>
                          </td>
                          <td className="px-6 py-4">
                            {value.date.toDate().toDateString()}{" "}
                            {value.date.toDate().toLocaleTimeString("ru-RU")}
                          </td>
                          <td className="px-6 py-4">
                            {/* <a href="#" onClick={(e:any) => deleteItem(value.id, value.name)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a> */}
                            <button
                              // type="submit"
                              onClick={(e: any) =>
                                deleteItem(value.id, value.name)
                              }
                              className="relative flex self-center h-[25px] w-24 items-center rounded-lg border-2 border-lime !text-lime text-sm 2xl:text-lg font-semibold justify-center overflow-hidden bg-transparent hover:!text-main-gray shadow-2xl transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:bg-lime before:duration-500 before:ease-out hover:shadow-lime-lighter hover:before:h-56 hover:before:w-56"
                            >
                              <span className="relative z-10 py-2">
                                Удалить
                              </span>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </section>
        </>
      )}
    </>
  );
}
