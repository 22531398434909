import React, { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  name: string;
  video: string;
  link: string;
  typeOf: string;
};

export const Project: React.FC<Props> = ({ name, video, link, typeOf }) => {
  const handleOnMouseOver = (e: React.MouseEvent<HTMLVideoElement>) =>
    e.currentTarget.play();
  const handleOnMouseOut = (e: React.MouseEvent<HTMLVideoElement>) =>
    e.currentTarget.pause();

  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "unset";
  }

  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="relative card rounded text-center overflow-hidden">
        <div className="absolute inset-0 bg-black/40">
          <h1 className="absolute start-4 text-center justify-center py-8 text-white text-2xl 3xl:text-3xl font-bold px-2">
            {name}
          </h1>
          <p className="absolute top-10 start-4 text-center justify-center py-8 text-white 2xl:text-xl font-bold px-2">
            {typeOf}
          </p>
        </div>

        <button
          onClick={toggleModal}
          className="absolute bottom-3 right-2 px-3 py-2 m-3 z-20 flex self-center  items-center rounded-lg border-2 border-white !text-white text-sm font-semibold justify-center overflow-hidden bg-transparent hover:border-lime shadow-2xl transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:bg-lime before:duration-500 before:ease-out hover:shadow-lime-lighter hover:before:h-56 hover:before:w-56"
        >
          <span className="relative z-10">
            {" "}
            {t("watch")} <i className="pl-2 fa-solid fa-play"></i>
          </span>
        </button>

        <video
          src={video}
          loop
          className="w-full h-auto z-10"
          muted
          playsInline
          autoPlay
          onMouseOver={handleOnMouseOver}
          onMouseOut={handleOnMouseOut}
        >
        </video>
      </div>
      {modal && (
        <div className="overflow-hidden overscroll-auto modal overlay z-30 fixed h-screen w-screen  inset-0 !z-40">
          <div className="overlay absolute h-screen w-screen z-50 inset-0 ">
            <div className="w-full h-full z-40 flex bg-gray-dark/50 justify-center items-center backdrop-blur-md"></div>
            <div
              onClick={toggleModal}
              className="animate-fade-up animate-once animate-duration-1000 absolute top-20 right-5 sm:right-10 md:right-14 xl:right-24 3xl:top-32 !z-40"
            >
              <i className="fa-solid fa-xmark text-5xl 3xl:text-6xl text-lime"></i>
            </div>
            <div className="overflow-hidden z-40 modal-content absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <iframe
                className=" !w-[320px] !h-[200px] sm:!h-[270px] sm:!w-[490px] md:!h-[350px] md:!w-[600px] lg:!h-[350px] lg:!w-[640px] xl:!h-[390px] xl:!w-[750px] 2xl:!h-[500px] 2xl:!w-[1024px] auto rounded-lg"
                src={link}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
