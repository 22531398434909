import React, { useEffect, useRef, useState } from "react";
import logoHeader from "../Media/logoheader.png";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Link as LinkRouter } from "react-router-dom";
import "../i18n";
import { useTranslation } from "react-i18next";
import styled, { css, createGlobalStyle } from "styled-components";
import { useAuth } from "../contexts/AuthContext2";
import { NavControl } from "../Panel/NavControl";
import ChangeLanguage from "./ChangeLanguage";

export function HeaderCopy() {
  const [headerScroll, setHeaderScroll] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const changeClassOnScroll = () => {
    const scrollValue = document.documentElement.scrollTop;
    if (scrollValue > 500) {
      setHeaderScroll(true);
    } else {
      setHeaderScroll(false);
    }
  };

  window.addEventListener("scroll", changeClassOnScroll);

  const ifDropdownColor = dropdown
    ? "bg-gray-dark/50"
    : "bg-gradient-to-b from-gray-dark to-transparent shadow-none";

  const btnClassName = headerScroll
    ? "bg-main-gray shadow-[rgba(134,85,255,0.2)_0px_0px_50px_0px]"
    : `${ifDropdownColor}`;
  const btnClasses = [
    "fixed w-full  px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 z-50 mx-auto text-white ",
    btnClassName,
  ];

  const HeadroomStyle = createGlobalStyle`
  .headroom-style {
    .headroom {
      top: 0;
      left: 0;
      right: 0;
      z-index: 50 !important;
      // overflow-x: hidden;
      position: fixed !important;
      // box-shadow: 0 10px 15px -3px rgba(6, 248, 0, 0.21), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    }

    .headroom-wrapper.headroom.headroom--unfixed {
      position: fixed !important;
      background-color: aliceblue;
      transform: translateY(0);
  }
    .headroom--scrolled {
      transition: transform 200ms ease-in-out;
    }
    .headroom--unpinned {
      position: fixed;
      transform: translateY(-100%);
    }
    .headroom--pinned {
      position: fixed;
      transform: translateY(0%);
    }
    
  }

  
`;

  const [scrollDir, setScrollDir] = useState("scrolling down");
  const [scrollDown, setScrolledDown] = useState(false);

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }

      if (scrollY > lastScrollY) {
        setScrolledDown(true);
      } else {
        setScrolledDown(false);
      }
      setScrollDir(scrollY > lastScrollY ? "scrolling down" : "scrolling up");
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);
    console.log(scrollDir);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollDir]);

  const { user } = useAuth();

  const dc = document.getElementById("dropdown");

  const toggleDropdown = () => {
    setDropdown(!dropdown);
    console.log(dropdown);
  };

  const dropdownColor = headerScroll ? "bg-main-gray" : "bg-gray-dark/50";

  const dropdownClassName = dropdown
    ? `top-[53px] opacity-100 ${dropdownColor} inset-x-0 items-start`
    : "top-[-400px] ";
  const dropdownClasses = [
    "dropdown  space-x-0 lg:space-x-8  absolute  lg:items-center   flex flex-col lg:flex-row lg:static py-5 px-5 sm:px-10 md:px-14 lg:px-0 lg:py-0  ",
    dropdownClassName,
  ];

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);
  const { t, i18n } = useTranslation();

  return (
    <>
      {user ? (
        <>
          <NavControl />
        </>
      ) : (
        <>
          <header id="header" className={btnClasses.join(" ")}>
            <div className="flex justify-between items-center py-2 3xl:py-5 3xl:text-xl">
              <div className="">
                <LinkRouter
                  to="/"
                  className="w-48 self-center"
                  onClick={scrollToTop}
                >
                  <img src={logoHeader} alt="" className="w-16 3xl:w-20" />
                </LinkRouter>
              </div>
              <div id="dropdown" className={dropdownClasses.join(" ")}>
                <HashLink
                  className="font-bold hover:text-lime  pb-4 lg:pb-0 lg:py-0"
                  to="/#showreel"
                >
                  {t("navAbout")}
                </HashLink>

                <LinkRouter
                  to="/projects"
                  className="font-bold hover:text-lime  pb-4 lg:pb-0 lg:py-0"
                >
                  {t("navProjects")}
                </LinkRouter>

                <LinkRouter
                  to="/workforus"
                  className="font-bold hover:text-lime pb-4 lg:pb-0 lg:py-0"
                >
                  {t("navJob")}
                </LinkRouter>

                <HashLink
                  className="border font-bold border-2 hover:border-lime hover:text-lime p-2  rounded hover"
                  to="/#form"
                >
                  {t("navContact")}
                </HashLink>
                <ChangeLanguage/>
              </div>
              <button onClick={toggleDropdown} className="  lg:hidden">
                <i className="fa-solid fa-bars text-3xl"></i>
              </button>
            </div>
          </header>
        </>
      )}
    </>
  );
}
