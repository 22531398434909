import React, { useState } from "react";
import card4 from "../../../Media/card4.png";
import { FormWork } from "./FormWork";
import { useTranslation } from "react-i18next";

type Props = {
  name: string;
  img: string;
  tasks: string[];
  requirements: string[];
  classIMG: string;
};

export const CardVacancy: React.FC<Props> = ({
  name,
  tasks,
  requirements,
  img,
  classIMG,
}) => {
  const [modalCard, setModalCard] = useState(false);

  const toggleModalCard = () => {
    setModalCard(!modalCard);
  };

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };
  const { t, i18n, ready } = useTranslation();  


  return (
    <>
      <div className="relative z-10 px-5 py-10 text-white flex flex-col justify-end  rounded-2xl border border-lime-lighter bg-[radial-gradient(ellipse_at_top_right,_var(--tw-gradient-stops))] from-lime-lighter via-transparent to-transparent">
        <div className="flex flex-col items-center">
          <img src={img} className={classIMG} alt="" />

          <h3 className="text-3xl 2xl:text-4xl text-center mb-5">{name}</h3>

          <button
            onClick={toggleModalCard}
            className="relative flex self-center h-[50px] w-40 items-center rounded-lg border-2 border-lime !text-lime text-sm  2xl:text-lg 3xl:text-xl font-semibold justify-center overflow-hidden bg-transparent hover:!text-main-gray shadow-2xl transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:bg-lime before:duration-500 before:ease-out hover:shadow-lime-lighter hover:before:h-56 hover:before:w-56"
          >
            <span className="relative z-10">{t("details")}</span>
          </button>
        </div>
        {modalCard && (
          <div className=" absolute z-20 inset-0 bg-main-gray px-5 md:px-8 2xl:px-10 py-5 text-white  rounded-2xl border border-white border-opacity-10 bg-[radial-gradient(ellipse_at_top_right,_var(--tw-gradient-stops))] from-lime-lighter via-transparent to-transparent">
            <div className="animate-fade animate-once animate-duration-1500 flex flex-col justify-center">
              <div
                className="flex justify-end "
              >
                <i
                  className="fa-solid fa-xmark text-5xl text-lime "
                  onClick={toggleModalCard}
                ></i>
              </div>
              <h3 className="text-2xl 2xl:text-3xl text-center font-bold pb-5">
                {name}
              </h3>
              <div className="pb-5 flex flex-col">
                <h2 className="text-xl 2xl:text-2xl pb-2">{t("tasks")}</h2>
                {tasks.map((task) => {
                  return (
                    <li className="flex items-center ">
                      <i className="fa-solid fa-minus px-2 self-start"></i>
                      <p className="text-sm 2xl:text-base 3xl:text-lg">
                        {task}
                      </p>
                    </li>
                  );
                })}
              </div>
              <div className="pb-5 flex flex-col">
                <h2 className="text-xl 2xl:text-2xl pb-2">{t("requirements")}</h2>
                {requirements.map((requirement) => {
                  return (
                    <li className="flex items-center">
                      <i className="fa-solid fa-minus px-2 self-start"></i>
                      <p className="text-sm 2xl:text-base 3xl:text-lg">
                        {requirement}
                      </p>
                    </li>
                  );
                })}
              </div>
              <button
                onClick={toggleModal}
                className="relative self-center flex self-center h-[50px] w-40 items-center rounded-lg border-2 border-lime !text-lime text-sm 2xl:text-lg 3xl:text-xl font-semibold justify-center overflow-hidden bg-transparent hover:!text-main-gray shadow-2xl transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:bg-lime before:duration-500 before:ease-out hover:shadow-lime-lighter hover:before:h-56 hover:before:w-56"
              >
                <span className="relative z-10">{t("apply")}</span>
              </button>
            </div>
          </div>
        )}
      </div>
      {modal && (
        <>

          <div className="overflow-hidden modal overlay z-50 fixed h-screen bg-main-gray w-screen inset-0 px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 2xl:px-40 2xl:gap-0 3xl:px-72  flex items-end flex-col justify-center  ">
          <div
            onClick={toggleModal}
            className="animate-fade-up animate-once animate-duration-1000 justify-self-end z-50"
          >
            <i className="fa-solid fa-xmark text-5xl text-lime"></i>
          </div>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-16">
              <div className="hidden md:block">
                <h3 className="text-3xl pb-5">{name}</h3>
                <div className="pb-5 flex flex-col">
                  <h2 className="text-xl pb-2">{t("tasks")}</h2>
                  {tasks.map((task) => {
                    return (
                      <li className="flex items-center">
                        <i className="fa-solid fa-minus px-2"></i>
                        <p>{task}</p>
                      </li>
                    );
                  })}
                </div>
                <div className="pb-5 flex flex-col">
                  <h2 className="text-xl pb-2">{t("requirements")}</h2>
                  {requirements.map((requirement) => {
                    return (
                      <li className="flex items-center">
                        <i className="fa-solid fa-minus px-2"></i>
                        <p>{requirement}</p>
                      </li>
                    );
                  })}
                </div>
              </div>
              <FormWork vacancy={name} />
            </div>
          </div>
        </>
      )}
    </>
  );
};
