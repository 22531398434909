import React from 'react'
import MainSection from './Components/MainSection'
import About from './Components/About'
import { HeaderCopy } from '../NavBar'
import Showreel from './Components/Showreel'
import FormContact from './Components/FormContact'
import ProjectsMain from './Components/ProjectsMain'

export default function Main() {


  return (
    <>
    <HeaderCopy/>
    <MainSection/>
    <Showreel/>
    <ProjectsMain/>
    <About/>
    <FormContact/> 
    </>
  )
}
