import React, { useState } from "react";
import { Card } from "./Card";
import video2 from "../../../Media/video2.mp4";
import video3 from "../../../Media/video3.mp4";
import video4 from "../../../Media/video4.mp4";
import video5 from "../../../Media/video5.mp4";
import { Link as LinkRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ProjectsMain() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <section
        id="projectsmain"
        className="px-5 pt-10 3xl:pt-16 xl:px-24 lg:px-16 md:px-14 sm:px-10 border-b border-lime-lighter h-auto  bg-main-gray y
        z-30 relative text-white"
      >
        <h3 className="text-3xl font-bold mb-10 3xl:mb-16 2xl:text-4xl 3xl:text-5xl ">
        {t("mainProjects")}
        </h3>

        <div className="grid justify-items-center gap-x-16 gap-y-8 grid-cols-1 lg:grid-cols-2">
          <Card
            name={t("project1")}
            desc={t("project1Desc")}
            video={video5}

          />
          <Card
            name={t("project2")}
            desc={t("project2Desc")}
            video={video2}          />
          <Card
            name={t("project3")}
            desc={t("project3Desc")}
            video={video3}           />
          <Card
            name={t("project4")}
            desc={t("project4Desc")}
            video={video4} 
          />
        </div>
        <LinkRouter to="/projects">
          <div className="flex justify-center py-8 col-span-3">
            <div className="flex gap-4">
              <button className="relative flex self-center h-[50px] p-5 3xl:w-auto  items-center rounded-lg border-2 border-lime !text-lime text-sm 2xl:text-lg 3xl:text-xl font-semibold justify-center overflow-hidden bg-transparent hover:!text-main-gray shadow-2xl transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:bg-lime before:duration-500 before:ease-out hover:shadow-lime-lighter hover:before:h-56 hover:before:w-56">
                <span className="relative z-10">{t("viewAll")}</span>
              </button>
            </div>
          </div>
        </LinkRouter>
      </section>
    </>
  );
}
