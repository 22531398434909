import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext2";
import { NavControl } from "./NavControl";

export function Form() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { signIn } = useAuth();
  const [alert, setAlert] = useState(false);
  const [formError, setFormError] = useState<string>("Нет доступа");

  const { user } = useAuth();

  const handleLogin = async (e: any) => {
    e.preventDefault();
    setAlert(true);

    try {
      await signIn(email, password);
      setFormError("Успешный вход");
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      {user !== null && <NavControl />}
      <section className="bg-main-gray w-full h-full absolute inset-0">
        <form
          className="text-white my-20 bg-main-gray  flex flex-col w-2/3 lg:w-1/3 m-auto  p-6 rounded-2xl border border-lime-lighter bg-[radial-gradient(ellipse_at_top_right,_var(--tw-gradient-stops))] from-lime-lighter via-transparent to-transparent"
          onSubmit={handleLogin}
        >
          <h3 className="text-center font-bold text-3xl mb-10">Войти</h3>
          {alert && (
            <>
              <div className="mb-3">
                <span className="text-sm 2xl:text-lg 3xl:text-xl text-lime">
                  {formError}
                </span>
              </div>
            </>
          )}
          <div className="mb-3">
            <input
              type="text"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder="Email"
              className="w-full border-lime-lighter bg-main-gray border-b-2  px-3 py-2 outline-none focus:bg-main-gray transition duration-100 focus:border-lime"
            />
          </div>
          <div className="mb-3">
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              placeholder="Пароль"
              className="w-full border-lime-lighter bg-main-gray border-b-2  px-3 py-2 outline-none focus:bg-main-gray transition duration-100 focus:border-lime"
            />
          </div>
          <button
            type="submit"
            className="relative flex self-center  h-[50px] w-full items-center rounded-lg border-2 border-lime !text-lime text-sm 2xl:text-lg 3xl:text-xl font-semibold justify-center overflow-hidden bg-transparent hover:!text-main-gray shadow-2xl transition-all before:absolute before:h-0 before:w-0 before:rounded-full before:bg-lime before:duration-500 before:ease-out hover:shadow-lime-lighter hover:before:h-56 hover:before:w-full"
          >
            <span className="relative z-10">Войти</span>
          </button>
        </form>
        <div></div>
      </section>
    </>
  );
}
