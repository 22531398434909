import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Work from "../Pages/Work/Work";
import Main from "../Pages/Home/Main";
import Projects from "../Pages/Projects/Projects";
import ProjectsControl from "../Panel/ProjectsControl";
import ShowreelControl from "../Panel/ShowreelControl";
import Requests from "../Panel/Requests";
import { Form } from "../Panel/Form";

export const router = createBrowserRouter(
  [
  {
    path: "/",
    element: <App />,
    children: [
      { path: "", element: < Main/> },
      { path: "workforus", element: <Work /> },
      { path: "projects", element: <Projects /> },
      { path: "control/projects", element: <ProjectsControl /> },
      { path: "control/showreel", element: <ShowreelControl /> },
      { path: "control/requests", element: <Requests /> },
      { path: "control/", element: <Form /> },

    ],
  },
]);