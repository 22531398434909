import React, { useEffect, useState } from "react";
import mainlogo_ru from "../../../Media/logo_artwall_ru.png";
import mainlogo_en from "../../../Media/logo_artwall_en.png";

import BgVideo from "../../../Media/showreel.mp4";
import { useTranslation } from "react-i18next";

export default function MainSection() {
  const { t, i18n, ready } = useTranslation();

  return (
    <section
      id="main"
      className="w-full max-w-none relative z-20 flex h-screen justify-items-center place-items-center items-center flex-col overflow-hidden"
    >
      <div className="relative flex items-center justify-center w-screen h-screen overflow-hidden">
        <div className="relative z-30 p-5 text-white rounded-xl">
          {i18n.language === "ru" ? (
            <>
              <img
                src={mainlogo_ru}
                width=""
                className="w-[200px] 2xl:w-[230px]  xl:w-[200px] lg:w-[200px] md:w-[220px] sm:w-[200px]"
                alt=""
              />
            </>
          ) : (
            <>
              <img
                src={mainlogo_en}
                width=""
                className="w-[200px] 2xl:w-[230px]  xl:w-[200px] lg:w-[200px] md:w-[220px] sm:w-[200px]"
                alt=""
              />
            </>
          )}
        </div>
        <div className="absolute bg-gradient-to-t  bottom-0 z-20 right-0 left-0 h-screen from-gray-dark to-transparent"></div>
      </div>
      <video
        className="fixed z-10 w-auto lg:w-full lg:w-auto 2xl:w-full  top-0 min-h-full max-w-none"
        src={BgVideo}
        autoPlay
        muted
        playsInline
        loop
      ></video>
    </section>
  );
}
