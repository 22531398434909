import React from "react";
import { Outlet } from "react-router";
import ScrollToTop from "./ScrollTop";
import { AuthProvider, useAuth } from "./contexts/AuthContext2";

function App() {
  const { user } = useAuth();
  console.log(user)
  return (
    <>
      <AuthProvider>
        <ScrollToTop />
        <Outlet />
      </AuthProvider>
    </>
  );
}

export default App;
