import React, { useEffect, useState } from "react";
import video1 from "../../../Media/video1.mp4";
import { useTranslation } from "react-i18next";
import {
  Firestore,
  limit,
  limitToLast,
  orderBy,
  query,
  serverTimestamp,
} from "firebase/firestore";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import { db } from "../../../firebase";

export default function Showreel() {
  const [mouseOver, isMouseOver] = useState(false);
  const [data, setData] = React.useState<any>();

  const handleOnMouseOver = (e: React.MouseEvent<HTMLElement>) => {
    isMouseOver(true);
  };
  const handleOnMouseOut = (e: React.MouseEvent<HTMLElement>) =>
    isMouseOver(false);

  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "unset";
  }
  const { t, i18n } = useTranslation();

  const getData = async () => {
    const valRef = collection(db, "showreel");
    const dataDB = await getDocs(valRef);
    const allData = dataDB.docs.map((val) => ({ id: val.id, ...val.data() }));

    const q = query(
      collection(db, "showreel"),
      orderBy("date"),
      limitToLast(1)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setData(doc.data());
    });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <section
        id="showreel"
        className={
          modal
            ? "pt-10 3xl:pt-16 2xl:h-screen bg-main-gray z-40 relative text-white"
            : "pt-10 3xl:pt-16 xl:h-screen bg-main-gray z-30 relative text-white"
        }
      >
        <h3 className="px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 text-3xl 2xl:text-4xl 3xl:text-5xl font-bold mb-10 3xl:mb-16">
          {t("srHeader")}
        </h3>
        <p className="px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 2xl:text-lg 3xl:text-xl">
          {t("srDesc")}
        </p>
        <p className="px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 mb-10 2xl:text-lg 3xl:text-xl">
          {t("srDesc2")}
        </p>

        {data !== undefined && data !== null && (
          <>
            <div
              className="group card relative overflow-hidden h-5/6 text-center z-40"
              onMouseOver={handleOnMouseOver}
              onMouseOut={handleOnMouseOut}
            >
              {mouseOver ? (
                <button
                  className=" absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-lime  text-7xl sm:text-8xl z-40"
                  onClick={toggleModal}
                >
                  <i className="animate-fade-up animate-duration-[1500ms] animate-ease-in-out animate-normal animate-fill-forwards fa-solid fa-play z-20"></i>{" "}
                </button>
              ) : (
                <>
                  <div className="absolute inset-0 h-full bg-black/25 "></div>
                  <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    <h1 className="animate-fade-up animate-duration-[1500ms] animate-ease-in-out animate-normal animate-fill-forwards text-3xl lg:text-5xl 3xl:text-6xl">
                      SHOWREEL
                    </h1>
                  </div>
                </>
              )}

              <video
                src={data.url}
                loop
                className="w-auto lg:w-full h-auto z-40 transition duration-200 group-hover:scale-110"
                muted
                playsInline
                autoPlay
              ></video>
            </div>
            {modal && (
              <div className="overflow-hidden overscroll-auto modal overlay  fixed h-screen w-screen inset-0 !z-40">
                <div className="overlay absolute h-screen w-screen  top-0 right-0 bottom-0 ">
                  <div className="w-full h-full z-40 flex bg-gray-dark/50 justify-center items-center backdrop-blur-md"></div>
                  <div
                    onClick={toggleModal}
                    className="animate-fade-up animate-once animate-duration-1000 absolute top-20 right-5 sm:right-10 md:right-14 xl:right-24 3xl:top-32 !z-40"
                  >
                    <i className="fa-solid fa-xmark text-5xl 3xl:text-6xl text-lime"></i>
                  </div>
                  <div className="overflow-hidden z-40 modal-content absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ">
                    <iframe
                      className=" !w-[320px] !h-[200px] sm:!h-[270px] sm:!w-[490px] md:!h-[350px] md:!w-[600px] lg:!h-[350px] lg:!w-[640px] xl:!h-[390px] xl:!w-[750px] 2xl:!h-[500px] 2xl:!w-[1024px] auto rounded-lg"
                      src={data.ybLink}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </section>
    </>
  );
}
