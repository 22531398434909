import i18next from "i18next";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  ru: {
    translation: {
      srHeader: "МЫ - ARTWALL",
      navProjects: "Проекты",
      navAbout: "О нас",
      navContact: "Связаться с нами",
      navJob: "Работа у нас",
      changeLanguageButtonRU: "RU",
      changeLanguageButtonEN: "EN",
      srDesc:
        "Анимационная студия из Уфы. За 10 лет работы в медиаиндустрии успела поработать со студиями Союзмультфильм, Делай, Муха.",
      srDesc2:
        "Команда закрывает все этапы пайплайна от идеи и сценария до постпроизводства",
      mainProjects: "ПРОЕКТЫ",
      project1: "Соня и Лёня",
      project1Desc:
        "Мультсериал о приключениях шустрой девочки Сони и ее верного друга",
      project2: "Пушкин",
      project2Desc:
        "Весёлый и озорной мультсериал о приключениях великого поэта Александра Пушкина в Михайловском",
      project3: "Муса Гареев",
      project3Desc:
        "Короткометражный фильм о летчике, который справился с трудностями и стал героем",
      project4: "Все перевернется",
      project4Desc:
        "Клип на песню “Все перевернется” группы Пикник в технике перекладной анимации",
      viewAll: "Больше проектов",
      whatWeDo: "ЧТО МЫ ДЕЛАЕМ",
      aboutDesc:
        "Мы умеем разрабатывать анимационный и видео-контент с нуля, шаг за шагом выполняя все этапы производства.",
      aboutDesc2:
        "Сможем сделать для вас как отдельный этап производства, например анимацию так и весь проект целиком, от концепции до финального монтажа.",
      scenario: "Сценарий",
      scenarioDesc: "Напишем свежий и захватывающий сценарий.",
      animatic: "Раскадровка и аниматик",
      animaticDesc:
        "Подберем убедительный образ для иллюстрации действия и передадим характер каждого персонажа через пластику движений.",
      visual: "Визуальная разработка",
      visualDesc:
        "Придумаем уникальный визуальный стиль, запоминающийся облик персонажей и живое окружение для вашего проекта.",
      rigging: "Риггинг",
      riggingDesc:
        "Сделаем качественные риги, чтобы анимация была быстрой и удобной.",
      animation: "Анимация",
      animationDesc:
        "Сделаем убедительные движения в соответсвии с режиссерской задачей.",
      edit: "Монтаж и композ",
      editDesc:
        "Соберем готовые шоты в единое целое, наполним кадры жизнью и атмосферностью",
      getInTouch: "СВЯЗАТЬСЯ С НАМИ",
      formName: "Имя*",
      formPhone: "Телефон*",
      formMessage: "Сообщение",
      formRequired: "*Обязательное поле",
      send: "Отправить",
      footerAdressDesc: "АДРЕС",
      footerAdress: "г. Уфа, Заки Валиди 32/2, 5 этаж",
      footerDesc: "Аrtwall студия анимации и постпродакшн в Уфе",
      formMedia: "Ссылка на соцсети",
      formFirstName: "Имя*",
      formLastName: "Фамилия*",
      formPortfolio: "Ссылка на портфолио",
      personalData:
        "Нажимая на кнопку, вы даете согласие на обработку персональных данных",
      successSend: "Заявка успешно отправлена!",
      emptyError: "Поле не может быть пустым",
      emailError: "Некорректный email",
      numberError: "Некорректный номер телефона",
      openVacancies: "ОТКРЫТЫЕ ВАКАНСИИ",
      vacanciesDesc1:
        "Мы - команда аниматоров, которые ищут талантливых людей. Если у вас есть опыт в создании анимаций, то отправьте нам свое портфолио.",
      vacanciesDesc2:
        "После его рассмотрения мы свяжемся с вами, обсудим условия работы, оплату и, возможно, вы станете частью нашей дружной команды!",
      ThreeDanim: "3D-аниматор",
      tasks: "Задачи",
      ThreeDanimDuties1:
        "Настройка параметров и подготовка 3D сцен для анимации",
      ThreeDanimDuties2:
        "Анимация действий и движений персонажей согласно раскадровкам",
      ThreeDanimDuties3: "Создание анимации движения камеры",
      requirements: "Требования",
      ThreeDanimReqieremnet1:
        "Владение программами для создания 3D моделей и анимации (Autodesk Maya, Blender 3D)",
      ThreeDanimReqieremnet2: "Умение работать с покадровой анимацией",
      ThreeDanimReqieremnet3:
        "Понимание основных принципов классической 3D анимации",
      apply: "Откликнуться",
      motionDesigner: "Моушн-дизайнер",
      tasksMD1: "Анимация сцен и шотов в соответствии с раскадровками",
      tasksMD2: "Разработка 2D и 3D видео-эффектов",
      tasksMD3: "Пост-обработка и композитинг",
      requirementsMD1:
        "Умение работать с референсами, придерживаться заданного стиля",
      requirementsMD2:
        "Уверенное владение программами для 2D/3D анимации (например, After Effects, Maya, Cinema 4D)",
      requirementsMD3: "Готовность к работе в команде",
      TwoDH: "2D-художник",
      tasks2DH1: "Создание иллюстраций на основе аниматика и раскадровки",
      tasks2DH2: "Создание концептов для анимации",
      tasks2DH3:
        "Разработка уникального визуального стиля для анимационных проектов",
      requirements2DH1:
        "Уверенное владение Adobe Photoshop, Procreate, PaintTool SAI или Adobe Illustrator",
      requirements2DH2: "Умение рисовать в разных стилях в зависимости от ТЗ",
      TwoDA_internship: "2D-аниматор (Стажировка)",
      tasks2DA_internship1:
        "Анимация сцен и кадров в соответствии с раскадровкой",
      tasks2DA_internship2: "Разработка анимационного стиля для мультфильмов",
      requirements2DA_internship1: "Желание работать в сфере анимации",
      requirements2DA_internship2: "Насмотренность",
      TwoDA_frame: "2D-аниматор (Покадровая анимация)",
      tasks2DA_frame1: "Анимация сцен и кадров в соответствии с раскадровкой",
      tasks2DA_frame2: "Создание 2D эффектов",
      tasks2DA_frame3: "Разработка анимационного стиля для мультфильмов",
      requirements2DA_frame1: "Желание работать в сфере анимации",
      requirements2DA_frame2: "Знание основ и принципов классической анимации",
      requirements2DA_frame3: "Навыки рисования",
      details: "Подробнее",
      TwoDA_cutout: "2D-аниматор (Перекладная анимация)",
      tasks2DA_cutout1: "Анимация сцен и кадров в соответствии с раскадровкой",
      tasks2DA_cutout2: "Разработка анимационного стиля для мультфильмов",
      requirements2DA_cutout1: "Желание работать в сфере анимации",
      requirements2DA_cutout2: "Знание основ и принципов классической анимации",
      requirements2DA_cutout3: "Насмотренность",
      watch: "Смотреть",
    },
  },
  en: {
    translation: {
      srHeader: "WE ARE ARTWALL",
      navProjects: "Projects",
      navAbout: "About us",
      navContact: "Contact us",
      navJob: "Work for us",
      changeLanguageButtonRU: "RU",
      changeLanguageButtonEN: "EN",
      srDesc:
        "Animation studio from Ufa. For the past 10 years  in the media industry, the company has successfully collaborated with Soyuzmultfilm, Delai, Mukha in the media industry.",
      srDesc2:
        "The team closes all stages of the pipeline from the idea and script to post-production",
      mainProjects: "PROJECTS",
      project1: "Sonya and Lenya",
      project1Desc:
        "An animated series about the adventures of a nimble girl Sonya and her faithful friend",
      project2: "Pushkin",
      project2Desc:
        "A funny and mischievous animated series about the adventures of the great poet Alexander Pushkin in Mikhaylovskoye",
      project3: "Musa Gareev",
      project3Desc:
        "A short film about a pilot who became a hero despite difficulties",
      project4: "Vse perevernetsya",
      project4Desc:
        "“Все перевернется” is a music video by Picnic, russian rock band.The video is a great example of cutout animation film.",
      viewAll: "View all",
      whatWeDo: "WHAT WE DO",
      aboutDesc:
        "We can create animation and video content from scratch, step by step completing all stages of production",
      aboutDesc2:
        "We'll do for you both a separate stage of production, for example animation, and the entire project, from the concept to the final installation",
      scenario: "Script",
      scenarioDesc: "Research and write engaging scripts",
      animatic: "Storyboard and animatics",
      animaticDesc:
        "We'll choose a convincing image to illustrate the action and convey the character of each character through the plasticity of movements.",
      visual: "Visual development",
      visualDesc:
        "We'll develop a distinctive visual identity, memorable characters, and an engaging environment for your project",
      rigging: "Rigging",
      riggingDesc:
        "We'll produce high-quality animation rigs for efficient and convenient use.",
      animation: "Animation",
      animationDesc:
        "We'll make convincing moves in accordance with the director's instructions.",
      edit: "Editing and compose",
      editDesc:
        "We'll assemble the finished animated shots filled with life and atmosphere in a final product ",
      getInTouch: "GET IN TOUCH",
      formName: "Name*",
      formPhone: "Phone*",
      formMessage: "Message",
      formRequired: "*Required",
      send: "Send",
      footerAdressDesc: "ADDRESS",
      footerAdress: "Ufa, Zaki Validi 32/2, 5th floor",
      footerDesc: "Artwall animation and post-production studio from Ufa",
      formMedia: "Media",
      formFirstName: "First name*",
      formLastName: "Last name*",
      formPortfolio: "Portfolio link",
      personalData:
        "By clicking 'Send' button, you consent to the processing of personal data",
      successSend: "Your application was successfully sent!",
      emptyError: "Field cannot be empty",
      emailError: "Incorrect email",
      numberError: "Incorrect phone number",
      openVacancies: "OPEN VACANCIES",
      vacanciesDesc1:
        "We are looking for talented people to join our team. If you have experience in creating animations, contact with us.",
      vacanciesDesc2:
        "After reviewing your portfolio, we would like to contact you to discuss working conditions and payment. Maybe you will become a part of our friendly team!",
      ThreeDanim: "3D-animator",
      tasks: "Tasks",
      ThreeDanimDuties1:
        "Customize settings and prepare 3D scenes for animation",
      ThreeDanimDuties2:
        "Animation of actions and movements of characters according to the storyboards",
      ThreeDanimDuties3: "Creating a camera motion animation",
      requirements: "Requirements",
      ThreeDanimReqieremnet1:
        "Knowledge of software for creating 3D-models and animations (Autodesk Maya, Blender 3D)",
      ThreeDanimReqieremnet2: "Working with frame-by-frame animation",
      ThreeDanimReqieremnet3:
        "Understanding of the basic principles of classical 3D animation",
      apply: "Apply",
      motionDesigner: "Motion-designer",
      tasksMD1: "Animation of scenes and shots in accordance with storyboards",
      tasksMD2: "Development of 2D and 3D video effects",
      tasksMD3: "Post-processing and compositing",
      requirementsMD1: "Working with references, adhere to a given style",
      requirementsMD2:
        "Knowledge of software for creating 3D-models and animations (Autodesk Maya, Blender 3D)",
      requirementsMD3: "Willingness to work in a team",
      TwoDH: "2D-artist",
      tasks2DH1: "Creating illustrations based on animatics and storyboards",
      tasks2DH2: "Creating concepts for animation",
      tasks2DH3: "Developing a unique visual style for animation projects",
      requirements2DH1:
        "Confident knowledge Adobe Photoshop, Procreate, PaintTool SAI or Adobe Illustrator",
      requirements2DH2:
        "Drawing in different styles depending on the technical specifications",
      TwoDA_internship: "2D-animator (Internship)",
      tasks2DA_internship1:
        "Animation of scenes and frames according to the storyboard",
      tasks2DA_internship2: "Developing an animation style for cartoons",
      requirements2DA_internship1: "Desire to work in the animation industry",
      requirements2DA_internship2: "Visual Experience",
      TwoDA_frame: "2D-animator (Frame-by-frame animation)",
      tasks2DA_frame1:
        "Animation of scenes and frames according to the storyboard",
      tasks2DA_frame2: "Creating 2D effects",
      tasks2DA_frame3:
        "Developing a unique visual style for animation projects",
      requirements2DA_frame1: "Desire to work in the field of animation",
      requirements2DA_frame2:
        "Understanding of the basic principles of classical 3D animation",
      requirements2DA_frame3: "Drawing skills",
      details: "More Details",
      TwoDA_cutout: "2D-animator (Cutout animation)",
      tasks2DA_cutout1:
        "Animation of scenes and frames according to the storyboard",
      tasks2DA_cutout2:
        "Developing a unique visual style for animation projects",
      requirements2DA_cutout1: "Desire to work in the field of animation",
      requirements2DA_cutout2:
        "Understanding of the basic principles of classical 3D animation",
      requirements2DA_cutout3: "Visual Experience",
      watch: "Watch",
    },
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: "ru",
});

export default i18n;
