import React, { useEffect, useState } from "react";
import logoHeader from "../Media/logoheader.png";
import { HashLink } from "react-router-hash-link";
import { Link as LinkRouter } from "react-router-dom";
import ChangeLanguage from "../Pages/ChangeLanguage";

export function NavControl() {
  
  const [headerScroll, setHeaderScroll] = useState(false);

  const changeClassOnScroll = () => {
    const scrollValue = document.documentElement.scrollTop;
    if (scrollValue > 500) {
      setHeaderScroll(true);
    } else {
      setHeaderScroll(false);
    }
  };

  window.addEventListener("scroll", changeClassOnScroll);

  const btnClassName = headerScroll
    ? "bg-main-gray shadow-[rgba(134,85,255,0.2)_0px_0px_50px_0px]"
    : "bg-main-gray shadow-[rgba(134,85,255,0.2)_0px_0px_50px_0px]";
  const btnClasses = [
    " fixed w-full px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 z-50 mx-auto text-white ",
    btnClassName,
  ];

  const [scrollDir, setScrollDir] = useState("scrolling down");
  const [scrollDown, setScrolledDown] = useState(false);

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }

      if (scrollY > lastScrollY) {
        setScrolledDown(true);
      } else {
        setScrolledDown(false);
      }
      setScrollDir(scrollY > lastScrollY ? "scrolling down" : "scrolling up");
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollDir]);

  const [dropdown, setDropdown] = useState(false);

  const dc = document.getElementById("dropdown");

  const toggleDropdown = () => {
    setDropdown(!dropdown);
  };

  const dropdownClassName = dropdown
    ? " top-[53px] opacity-100 bg-main-gray inset-x-0 items-start  "
    : "top-[-400px] ";
  const dropdownClasses = [
    "dropdown  space-x-0 lg:space-x-8  absolute  flex flex-col lg:flex-row lg:static  py-5 px-5 sm:px-10 md:px-14 lg:px-0 lg:py-0 ",
    dropdownClassName,
  ];


  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
        <header id="header" className={btnClasses.join(" ")}>
          <div className="flex justify-between items-center py-2 3xl:py-5 3xl:text-xl">
            <div className="">
              <LinkRouter to="/" className="w-48 self-center">
                <img src={logoHeader} alt="" className="w-16 3xl:w-20" />
              </LinkRouter>
            </div>
            <div id="dropdown" className={dropdownClasses.join(" ")}>
            <LinkRouter
                to="/control/showreel"
                className="font-bold hover:text-lime pb-4 lg:pb-0 lg:py-2"
                onClick={scrollToTop}
              >
                Поменять SHOWREEL
              </LinkRouter>
              <LinkRouter
                to="/control/requests"
                className="font-bold hover:text-lime pb-4 lg:pb-0 lg:py-2"
                onClick={scrollToTop}
              >
                Заявки
              </LinkRouter>
              <LinkRouter
                to="/control/projects"
                className="font-bold hover:text-lime pb-4 lg:pb-0 lg:py-2"
                onClick={scrollToTop}
              >
                Управление проектами
              </LinkRouter>
              <HashLink
                className="font-bold hover:text-lime pb-4 lg:pb-0 lg:py-2"
                to="/#showreel"
              >
                О нас
              </HashLink>

              <LinkRouter
                to="/projects"
                className="font-bold hover:text-lime pb-4 lg:pb-0 lg:py-2"
                onClick={scrollToTop}
              >
                Проекты
              </LinkRouter>

              <LinkRouter
                to="/workforus"
                className="font-bold hover:text-lime pb-4 lg:pb-0 lg:py-2"
                onClick={scrollToTop}
              >
                Работа у нас
              </LinkRouter>

              <HashLink
                className="border font-bold border-2 hover:border-lime hover:text-lime p-2  rounded hover"
                to="/#form"
              >
                Связаться с нами
              </HashLink>
              <ChangeLanguage/>

            </div>
            <button onClick={toggleDropdown} className="lg:hidden">
              <i className="fa-solid fa-bars text-3xl"></i>
            </button>
          </div>
        </header>
    </>
  );
}
