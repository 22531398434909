import React from "react";

type Props = {
    img: string;
    name: string;
  desc: string;
};

export const AboutCard: React.FC<Props> = ({ img, name, desc }) => {
  const handleOnMouseOver = (e: React.MouseEvent<HTMLVideoElement>) =>
    e.currentTarget.play();
  const handleOnMouseOut = (e: React.MouseEvent<HTMLVideoElement>) =>
    e.currentTarget.pause();

  return (
    <>
        <div className="relative text-white flex flex-col items-center justify-start ">
          <div className="border border-lime-lighter bg-lime rounded-full flex justify-center items-center w-16 h-16 mb-5">

            <div className="text-main-gray font-bold text-3xl">
              <img src={img} alt="" className="w-[50px]" />
            </div>
          </div>
          <h3 className="text-2xl mb-3 text-lime 2xl:text-3xl">{name}</h3>
          <p className="text-center 2xl:text-base   3xl:text-xl ">
            {desc}
          </p>
        </div>
    </>
  );
};
