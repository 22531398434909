import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n, { changeLanguage } from "i18next";

export default function ChangeLanguage() {
  const [RUselected, isRUSelected] = useState(true);
  const [ENselected, isENSelected] = useState(false);
  const ruBtnClassName = RUselected
    ? `w-11 border font-bold border-2 hover:border-lime hover:text-lime p-2  rounded`
    : "hover:border hover:border-2 hover:rounded hover:border-lime hover:text-lime";
  const ruBtnClasses = ["w-11 p-2", ruBtnClassName];

  const enBtnClassName = ENselected
  ? `w-11 border font-bold border-2 hover:border-lime hover:text-lime p-2  rounded`
  : "hover:border hover:border-2 hover:rounded hover:border-lime hover:text-lime";
  const enBtnClasses = ["w-11 p-2", enBtnClassName];
  const changeLanguageToRU = () => {
    i18n.changeLanguage("ru");
    if (i18n.language == "ru") {
      isRUSelected(true);
      isENSelected(false);
    }
  };

  const changeLanguageToEN = () => {
    i18n.changeLanguage("en");
    if (i18n.language == "en") {
      isRUSelected(false);
      isENSelected(true);
    }
  };
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (i18n.language == "ru") {
      isRUSelected(true);
      isENSelected(false);
    }
    if (i18n.language == "en") {
      isRUSelected(false);
      isENSelected(true);
    }
  }, [RUselected, ENselected])
  return (
    <>
      <div className="flex rounded space-x-2  pt-4 lg:pb-0 lg:py-0">
        <button className={ruBtnClasses.join(" ")} onClick={changeLanguageToRU}>
          {t("changeLanguageButtonRU")}
        </button>
        <button className={enBtnClasses.join(" ")} onClick={changeLanguageToEN}>
          {t("changeLanguageButtonEN")}
        </button>
      </div>
    </>
  );
}
