import React from "react";
import { Header } from "../Header";
import { CardVacancy } from "./Components/CardVacancy";
import Footer2 from "../Footer2";
import DH from "../../Media/2DH.png";
import DA from "../../Media/3DA.png";
import motion from "../../Media/motion.png";
import perekladka from "../../Media/2DAperekladka.png";
import DA_frame from "../../Media/2DA_frame.png";
import DA_internship from "../../Media/2DA_internship.png";
import { useTranslation } from "react-i18next";

export default function Work() {
const { t, i18n, ready } = useTranslation();  
const tasks3d = [
    t("ThreeDanimDuties1"),
    t("ThreeDanimDuties2"),
    t("ThreeDanimDuties3"),   
  ];

  const requirements3D = [
    t("ThreeDanimReqieremnet1"),
    t("ThreeDanimReqieremnet2"),
    t("ThreeDanimReqieremnet3"), 
  ];

  const tasksMD = [
    t("tasksMD1"),
    t("tasksMD2"),
    t("tasksMD3"), 
  ];

  const requirementsMD = [
    t("requirementsMD1"),
    t("requirementsMD2"),
    t("requirementsMD3"), 
  ];

  const tasks2DH = [
    t("tasks2DH1"),
    t("tasks2DH2"),
    t("tasks2DH3"),
  ];

  const requirements2DH = [
    t("requirements2DH1"),
    t("requirements2DH2"),
  ];

  const tasks2DA_frame = [
    t("tasks2DA_frame1"),
    t("tasks2DA_frame2"),
    t("tasks2DA_frame3"),

  ];

  const requirements2DA_frame = [
    t("requirements2DA_frame1"),
    t("requirements2DA_frame2"),
    t("requirements2DA_frame3"),
  ];

  const tasks2DA_cutout = [
    t("tasks2DA_cutout1"),
    t("tasks2DA_cutout2"),
    ];

  const requirements2DA_cutout = [
    t("requirements2DA_cutout1"),
    t("requirements2DA_cutout2"),
    t("requirements2DA_cutout3")
  ];

  const tasks2DA_internship = [
    t("tasks2DA_internship1"),
    t("tasks2DA_internship2"),
  ];

  const requirements2DA_internship = [
    t("requirements2DA_internship1"),
    t("requirements2DA_internship2"),
  ];

  return (
    <>
      <Header />
      <section
        id="work"
        className="px-5 xl:px-24 lg:px-16 md:px-14 sm:px-10 pb-10 pt-[108px] 3xl:pb-16 3xl:pt-[132px] clear-both bg-main-gray text-white"
      >
        <h3 className="pb-10 3xl:pb-16 text-3xl 2xl:text-4xl 3xl:text-5xl font-bold">
          {t("openVacancies")}
        </h3>
        <p className="pb-10 3xl:pb-16 2xl:text-lg 3xl:text-xl">
          {t("vacanciesDesc1")}
          <br />
          {t("vacanciesDesc2")}
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 md:gap-10 lg:gap-5 3xl:grid-cols-3">
          <CardVacancy
            name={t("ThreeDanim")}
            tasks={tasks3d}
            requirements={requirements3D}
            img={DA}
            classIMG="my-5 self-center w-[220px] sm:w-[150px] lg:w-[190px] xl:w-[160px] 2xl:w-[170px] 3xl:w-[250px]"
          />
          <CardVacancy
            name={t("motionDesigner")}
            tasks={tasksMD}
            requirements={requirementsMD}
            img={motion}
            classIMG="mb-5 self-center w-[160px] sm:w-[120px] 2xl:w-[130px] 3xl:w-[190px] "
          />
          <CardVacancy
            name={t("TwoDH")}
            tasks={tasks2DH}
            requirements={requirements2DH}
            img={DH}
            classIMG="my-7 self-center w-[350px] sm:w-[230px] 2xl:my-16 lg:my-14 xl:w-[200px] 3xl:w-[250px] 4xl:w-[300px]"
          />
          <CardVacancy
            name={t("TwoDA_internship")}
            tasks={tasks2DA_internship}
            requirements={requirements2DA_internship}
            img={DA_internship}
            classIMG=" self-center w-[250px] sm:w-[170px] md:my-5 lg:my-14 3xl:w-[190px] 3xl:my-20 4xl:w-[300px]"
          />
          <CardVacancy
            name={t("TwoDA_frame")}
            tasks={tasks2DA_frame}
            requirements={requirements2DA_frame}
            img={DA_frame}
            classIMG="mb-5 self-center w-[220px] sm:w-[150px] md:w-[180px] lg:w-[200px] md:my-5 lg:my-10 xl:w-[150px] 3xl:w-[190px] 3xl:my-14 4xl:w-[300px]"
          />
          <CardVacancy
            name={t("TwoDA_cutout")}
            tasks={tasks2DA_cutout}
            requirements={requirements2DA_cutout}
            img={perekladka}
            classIMG="my-5 self-center w-[150px] sm:w-[120px] 3xl:w-[140px] 4xl:w-[200px]"
          />
        </div>
      </section>
      <Footer2 />
    </>
  );
}
